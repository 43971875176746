<template>
  <div>
    <div class="vote_type" :class="getSelectClass">
      <el-select
        :popper-append-to-body="false"
        @change="selectChange()"
        v-model="currentPeriod"
        placeholder="请选择"
      >
        <el-option
          v-for="item in periodList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <div
        class="vote_type_item"
        :class="!activeType ? getButtonClass : ''"
        @click="handleVoteMethodGroup()"
      >
        按文献分组投票
      </div>
      <div
        :class="activeType ? getButtonClass : ''"
        @click="handleVoteMethodName()"
        class="vote_type_item"
      >
        按专家姓名投票
      </div>
    </div>
    <div class="vote_time" :class="timeStyle">
      投票时间: {{ voteStartTime[0] }}年{{ voteStartTime[1] }}月{{
        voteStartTime[2]
      }}日- {{ voteEndTime[0] }}年{{ voteEndTime[1] }}月{{ voteEndTime[2] }}日
    </div>
  </div>
</template>

<script>
import { ElSelect, ElOption } from "element-plus";
export default {
  data() {
    return {
      role: 1,
      currentPeriod: this.period,
    };
  },
  components: {
    ElSelect,
    ElOption,
  },
  props: {
    period: {
      type: [Number, String],
      default: 1,
    },
    periodList: {
      type: Array,
    },
    voteEndTime: {
      type: Array,
    },
    voteStartTime: {
      type: Array,
    },
    timeStyle: {
      type: String,
    },
    getSelectClass: {
      type: String,
    },
    getButtonClass: {
      type: String,
    },
    activeType: {
      type: [Number, Boolean],
    },
  },
  watch: {
    period(newValue, oldValue) {
      this.currentPeriod = newValue;
    },
  },
  mounted() {
    console.log("this.periodList :>> ", this.periodList);
  },
  methods: {
    selectChange() {
      console.log(this.currentPeriod);
      this.$emit("selectChange", this.currentPeriod);
    },
    handleVoteMethodGroup() {
      this.$emit("handleVoteMethodGroup");
    },
    handleVoteMethodName() {
      this.$emit("handleVoteMethodName");
    },
  },
};
</script>

<style >
.vote_type {
  padding: 0 5.33vw;
  margin-top: 4vw;
  flex: none;
  display: flex;
  justify-content: space-between;
}
/* 大众 */
.mass_type .el-input__inner {
  border-radius: 4vw;
  height: 5.33vw;
  line-height: 5vw;
  width: 22.67vw;
  background: #9e004c;
  color: #fff;
  font-size: 3.2vw !important;
  border: none;
  text-align: center !important;
  padding: 0;
  padding-right: 0 !important;
}
/* 大众 */
.mass_type .el-select-dropdown__item.selected {
  color: #a70953;
  background: #fff !important;
}
/* 专家 */
.profic_type .el-input__inner {
  border-radius: 4vw;
  height: 5.33vw;
  line-height: 5vw;
  width: 22.67vw;
  background: #b7d635;
  color: #fff;
  font-size: 3.2vw !important;
  border: none;
  text-align: center !important;
  padding: 0;
  padding-right: 0 !important;
}
/* 专家 */
.profic_type .el-select-dropdown__item.selected {
  color: #b7d635;
  background: #fff !important;
}
.vote_type .el-select-dropdown__item.hover {
  background: #fff !important;
}
.vote_type .el-input__suffix {
  display: none;
}
.vote_type .el-select-dropdown__item {
  text-align: center;
}
.vote_type_item {
  font-size: 3.2vw;
  height: 5.33vw;
  line-height: 1vw;
  width: 29vw;
  border-radius: 5.33vw;
  background: #909499;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.mass_type_item_active {
  background: #9e004c;
}
.profic_type_item_active {
  background: #b7d635;
}
.vote_time {
  margin: 3.67vw 0 2vw 0;
  height: 8vw;
  text-align: center;
  line-height: 8vw;
  font-size: 3.2vw;
  font-weight: bold;
  flex: none;
}
</style>