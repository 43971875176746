<template>
    <div class="voteFooter" :class="getStyle()">
        <p>
          本项目中会传递医药前沿信息、研究进展和科学信息交流，仅供医疗卫生专业人士访问、参考及学习，请确保您的身份是医疗卫生专业人士，否则请退出。
        </p>
    </div>
</template>

<script>
export default {
  props: {
    role: {
      type: Number,
    },
    mode: { type: [Boolean, Number] },
  },
  methods: {
    getStyle() {
      if (!this.mode) {
        return "background";
      } else if (!this.role) return "background";
      else return "background_new";
    },
  },
};
</script>

<style >
.voteFooter {
  vertical-align: middle;
  padding: 2.67vw 5.33vw;
  font-size: 2vw;
  flex: none;
  display: flex;
  align-items: center;
}
.voteFooter p {
  line-height: 3.5vw;
}
.voteFooter .background {
  background: #eadce4;
}
.voteFooter .background_new {
  background: #f3f8e3;
}
</style>